import Helmet from "react-helmet"
import React from "react"
import ExtendedHomepage from "src/components/extendedHomepage"
import HomepageLayout from "src/layouts/homepage"
import CarbonateDisclosure from "src/components/carbonatedisclosure"
import {isIeEdge,isChrome,isFirefox} from "@tightrope/lpscripts/browserdetect"


const homepageData = import("./homepage.json");

export default function HomePage() {
  let disclosure = null;
  if(isChrome()){
    disclosure = <CarbonateDisclosure data={homepageData}></CarbonateDisclosure>;
  }
  else{
    disclosure = <CarbonateDisclosure data={homepageData}></CarbonateDisclosure>;
  }
  return(
    <HomepageLayout>
      <Helmet>
      <style type="text/css">{`


        
        `}</style>

      <title>Carbonate Browser</title></Helmet>
      <ExtendedHomepage data={homepageData}>
        {disclosure}
      </ExtendedHomepage>
    </HomepageLayout>
  )
}
